import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'

import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import Meta from 'components/meta'
import Wrapper from 'components/wrapper'

import { useAirtableWorks } from 'utils/use-airtable-works'
import { useSitePages } from 'utils/use-site-pages'

export default function PortfolioAllWorks({ location }) {
  const pageNodes = useSitePages()
  const [workList, setWorkList] = useState([])
  const airtable = useAirtableWorks()

  useEffect(() => {
    const buf = []
    pageNodes?.forEach((pageNode) => {
      const { pageContext, path: pageRoute } = pageNode ?? {}
      const { projectData } = pageContext ?? {}
      if (pageContext?.type === 'work') {
        buf.push(Object.assign({}, { pageRoute }, projectData))
      }
    })
    buf.sort((a, b) => a.title.localeCompare(b.title))
    setWorkList(buf)
  }, [])

  // TODO: replace with work timeline
  const renderWorkButton = (work) => {
    const workItem = airtable.find((o) => o.data.workId == work.contentKey)?.data
    return (
      <Button
        className="text-truncate"
        variant="outline-primary"
        onClick={() => navigate(`/${work.contentKey}`)}
      >
        {workItem?.title}
      </Button>
    )
  }

  return (
    <Wrapper location={location} variant="cover" bodyClass="bg-body-darker">
      <Meta title="All Works" />
      <Card className="w-75 mx-auto border-0">
        <Card.Header className="w-100 mb-3">
          <div className="mx-auto text-primary fs-4">All Works</div>
        </Card.Header>
        <Row className="g-1 justify-content-center">
          {workList?.map((work, workIndex) => (
            <Col xs="auto" key={workIndex}>
              {renderWorkButton(work)}
            </Col>
          ))}
        </Row>
      </Card>
    </Wrapper>
  )
}
